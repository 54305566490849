import React from 'react';
import { Popover, OverlayTrigger, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import utils from '../../../utils';

function SubNavPopover({
    navList,
    apppath,
    sendNavItemToShow,
    isActive,
    togglePopover,
    closePopover,
    navItem = null,
    navLevelThree = null,
    activeTab = null
}) {


    const handleNavSelection = (nav, url, title) => {
        sendNavItemToShow(nav, url, title); // Notify parent of the selection
        closePopover(); // Close the dropdown
    };

    return navList && navList.subNav && navList.subNav.length > 1 ? (
        <OverlayTrigger
            trigger="click"
            rootClose // Automatically close when clicking outside
            show={isActive} // Controlled by parent state
            onHide={closePopover} // Close dropdown on outside click
            placement="bottom-start"
            overlay={
                <Popover id='subnav-popover' className='p-04 max-width-100 border-radius-small border-none boxshadow-depth-16'>
                    {/* <div> */}
                        {
                            navList.subNav.map((item) => {
                                return (
                                    //check to see if a subnav of the subnav exists if it does loop through the third level subnav and display each item
                                    navLevelThree && navLevelThree.length > 0 && item.linkLabel === 'Capability Analysis By Company' ?
                                    <Row>
                                        <Col className='width-fit-content p-i-04'>
                                            <Button
                                                className='p-b-03 p-i-06 btn-border-primary-sm'
                                                id="third-level-nav"
                                                drop='end'
                                                title={item.linkLabel}
                                                show={true}
                                            >
                                                {utils.getSubNavLinkName(item.linkLabel)} <ChevronRight className='svg-12'/>
                                            </Button>
                                        </Col>
                                        <Col className='width-fit-content p-i-04 border-secondary-light-left'>
                                            {
                                                navLevelThree.map((levelThreeItem) => {
                                                    return (
                                                        <LinkContainer to={`/${apppath}/${item.urlForField}`}>
                                                            <Button 
                                                                className={ activeTab && activeTab.level_three && activeTab.level_three.fields.companyName === levelThreeItem.fields.companyName ? 
                                                                    'level-three-active display-block align-left p-b-03 p-i-06 third-level-nav' : 
                                                                        'third-level-nav display-block align-left p-b-03 p-i-06' }
                                                                onClick={() => handleNavSelection(levelThreeItem, item.urlForField, navList.groupingTitle)}
                                                            >
                                                                {levelThreeItem.fields.companyName}
                                                            </Button>
                                                        </LinkContainer>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>

                                    :
                                    //Otherwise just display the subnav item
                                    <Row>
                                        <Col className='width-fit-content'>
                                            <LinkContainer to={`/${apppath}/${item.urlForField}`}>
                                                <Button onClick={() => handleNavSelection(null, item.urlForField, navList.groupingTitle)} className='p-b-03 p-i-06 btn-ghost-sm' >{utils.getSubNavLinkName(item.linkLabel)}</Button>
                                            </LinkContainer>
                                        </Col>
                                        {
                                            navLevelThree && navLevelThree.length > 0 ?
                                                <Col className='border-secondary-light-left'></Col>
                                            : null
                                        }
                                    </Row>
                                )
                            })
                        }
                </Popover>
            }
        >
            <Button className={ activeTab && activeTab.title && activeTab.title === navItem ? 'tab-nav-btn active-tab' : 'tab-nav-btn'} onClick={() => togglePopover(navItem)} variant="secondary">
                {navItem}<ChevronDown className='svg-12 m-i-start-03'/>
            </Button>
        </OverlayTrigger>
    ) : (
        <LinkContainer to={`/${apppath}/${navList.subNav[0].urlForField}`}>
            <Button className={activeTab && activeTab.url === navItem.urlForField ? 'tab-nav-btn active-tab' : 'tab-nav-btn'} onClick={() => handleNavSelection(null, navList.subNav[0].urlForField, navList.groupingTitle)}>
                {navList && navList.subNav
                    ? navList.subNav[0].linkLabel
                    : navList?.groupingTitle || navItem}
            </Button>
        </LinkContainer>
    );
}

export default SubNavPopover;
