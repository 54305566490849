
import React, { useState, useEffect, useRef } from "react";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { useToast } from '../ToastProvider';
import utils from "../../utils"
import '../../styles/layout/_layout.scss'

function EnumSelector({enum_type, onChange, apiKey, disabled=false, value=null, isMulti=false, 
    clearAfterSelect=false, disableCreate=false, className=null, doAPICall=null, fetchedEnums=null, isClearable=false, isInTable=false}) {

  /*--------------------------State variables---------------------------*/
  const [allEnums, setAllEnums] = useState([])
  const [enumOptions, setEnumOptions] = useState([])
  const [deletedEnums, setDeletedEnums] = useState([])
  const [enumSelection, setEnumSelection] = useState(null)
  const [error, setError] = useState([]);
  const { addToast } = useToast();
  const [menuPlacement, setMenuPlacement] = useState("bottom");
  const controlRef = useRef(null);


  // for dynamic top or bottom dropdown menu placement
  // EnumSelector needs the prop isInTable={true}
  const calculateMenuPlacement = () => {
    if (!controlRef.current) {
      return;
    }
  
    // Get the bounding rectangle of the wrapper
    const rect = controlRef.current.getBoundingClientRect();  
    const viewportHeight = window.innerHeight;
  
    // Determine placement based on available space
    const shouldOpenUp = rect.bottom + 300 > viewportHeight; // Adjust menu height estimate
    const calculatedPlacement = shouldOpenUp ? "top" : "bottom";
      setMenuPlacement(calculatedPlacement);
  };
  
  

  useEffect(() => {
    if (!isInTable) {
      // If not in a table, fallback to default behavior - dropdowns open underneath 
      setMenuPlacement("bottom");
      return;
    }

    // Initial calculation
    calculateMenuPlacement();

    // Recalculate on scroll or resize
    const handleScrollOrResize = () => calculateMenuPlacement();
    window.addEventListener("scroll", handleScrollOrResize);
    window.addEventListener("resize", handleScrollOrResize);

    return () => {
      window.removeEventListener("scroll", handleScrollOrResize);
      window.removeEventListener("resize", handleScrollOrResize);
    };
  }, [isInTable]);



  useEffect(() => {
    if (!value || allEnums.length === 0) {
      setEnumSelection(null);
      return;
    }

    if (isMulti){
      setEnumSelection(
          allEnums.filter((e) => value.includes(e.label))
        )
    } else {
      const enums =  allEnums.filter((e) => value === e.label)
      if (enums.length > 0){
        setEnumSelection(enums[0])
      }
    }
    // React 18 ready
    return () => { };
  //eslint-disable-next-line
  }, [value, isMulti, allEnums])



  useEffect(()=>{
    if (!enumSelection){
      return;
    }
    setError('')
    const tempErrors = []
    if(isMulti){
      enumSelection.forEach((elt)=>{
        if (elt.value.deleted===true){
          tempErrors.push(elt.value.enum)
        }
      })

    } else{
      if (enumSelection.value.deleted===true){
        tempErrors.push(enumSelection.value.enum)
      }
    }
    
    if (tempErrors.length === 1){
      setError('This option has been deleted. Please consider replacing it.')
    } else if (tempErrors.length === 2){
      setError('The options '+ tempErrors[0] + ' and ' + tempErrors[1] +' have been deleted. Please consider replacing them.')
    } else if (tempErrors.length >2){
      const last = tempErrors.pop();
      const mainErrors = tempErrors.join(', ')
      setError('The options '+ mainErrors + ', and ' + last +' have been deleted. Please consider replacing them.')
    }
    // React 18 ready
    return () => { };
    // eslint-disable-next-line
  }, [enumSelection, isMulti])


/*-------------------------Get the requested enums-------------------*/
  useEffect(() => {
    async function fetchData() {
      if (doAPICall === false) {
        if (fetchedEnums) {
          // If fetchedEnums is available, use it instead of making an API call
          setEnumOptions(fetchedEnums);
          setAllEnums(fetchedEnums);
        }
  
      } else {
        // Fetch the enum data from the API if no pre fetchedEnums are passed
        let enumResult = await utils.getData('get_enums', apiKey, `?enum_type=${enum_type}&include_deleted=True`);
        if (typeof enumResult === 'string') {
          addToast({
            title: 'Failed to load enum data. Error code 2-a',
            body: enumResult,
          });
          return;
        }

        const temp = enumResult.map(en=>{return {label:en.enum, value: en}})
        // Sorting is pushed to the backend now.
        setEnumOptions(temp)
        setEnumOptions(temp.filter((en) => en.value.deleted === false));
        setAllEnums(temp);
        setDeletedEnums(enumResult.filter((en) => en.deleted === true));
      }
    }
    fetchData();
    // React 18 ready
    return () => { };
   // eslint-disable-next-line
  }, [enum_type, apiKey, addToast, fetchedEnums, doAPICall]);


  const insertEnumIfNew = async (enumSelection) => {
    let newEnum;
    // Inserts the enum into the database if user agrees and it passes the checks
    if (enumSelection.__isNew__){
      newEnum = enumSelection.value
      var deletedEnum = deletedEnums.filter(en => en.enum.toLowerCase() === enumSelection.value.toLowerCase())
      
      if (deletedEnum.length > 0){
        deletedEnum = deletedEnum[0]

        if (deletedEnum.replacement === null){
          alert("This enum has been deleted, please try another")
        } else {
          alert("This enum has been deleted, consider replacing with " + deletedEnum.replacement.enum)
        }
        return
      }
      const confirmation = window.confirm("Are you sure you want to add " + newEnum + " to the database?")
      if (confirmation === false) {
        return
      }
      const data = {enum_type: enum_type, enum: enumSelection.value, deleted: false, alt_enums: []} 
      const result = await utils.upsert('upsert_enum', apiKey, data)  
      if (typeof result === 'string') {
        window.alert("An error occured, please contact an administrator")
      } else {
         // Successfully added the enum, update its state to not new
        enumSelection.__isNew__ = false; // Mark this enum as not new
        let tempOptions = [...enumOptions]
        tempOptions.push({label:enumSelection.label, value:enumSelection.value})
        setEnumOptions(tempOptions); 
      }
    }
  }



  /*-------------------------Select the enum----------------------------------*/
  async function handleChange(e) {
    if (!e) {
      setEnumSelection(null)
      await onChange({ value: null })
      return
    }
    if (isMulti) {

      for (let enumSelection of e) {
        await insertEnumIfNew(enumSelection)
      }
    } else {
      await insertEnumIfNew(e)
    }

    if (clearAfterSelect !== true) {
      setEnumSelection(e)
    }
    await onChange(e)
  }


  return (
    <div ref={controlRef}>
      {disableCreate ? (
        <Select
          className={className || "select-lg"}
          isClearable={isClearable}
          options={enumOptions}
          isDisabled={disabled}
          onChange={handleChange}
          value={enumSelection}
          isMulti={isMulti}
          ref={(select) => {
            if (select && select.controlRef) {
              controlRef.current = select.controlRef; // Attach the DOM node
            }
          }}
          menuPlacement={menuPlacement}
          //menuPortalTarget={document.body}
          // styles={{
          //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          // }}
        />
      ) : (
        <CreatableSelect
          className={className || "select-lg"}
          isClearable={isClearable}
          options={enumOptions}
          onChange={handleChange}
          value={enumSelection}
          isDisabled={disabled}
          isMulti={isMulti}
          ref={(select) => {
            if (select && select.controlRef) {
              controlRef.current = select.controlRef; // Attach the DOM node
            }
          }}
          menuPlacement={menuPlacement}
          //menuPortalTarget={document.body}
          // styles={{
          //   menuPortal: (base) => ({ ...base, zIndex: 9999 }) // keeps dropdown menu in front of everything
          // }}
        />
      )}
      {error}
    </div>
  );
}

export default EnumSelector;