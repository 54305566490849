import React, { useEffect, useState } from "react";
import ListPagination from "./Layout/ListPagination";
import { Spinner } from "react-bootstrap";
import useScrollToLocation from "./useScrollToLocation";
import FormattedArticleList from "./News/FormattedArticleList";

function BasicElasticEngine({ engineToUse, filterItems, paginationsize=25, formatForNewsList=null, filterChanged, getNewsArticleTags, inputFilters, filterOptions=null, filters}) {

    const [ curData, setCurData ] = useState([]);
    const [ curPage, setCurPage ] = useState(1);
    const [ pageChanged, setPageChanged ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ totalResults, setTotalResults ] = useState(0);

    useScrollToLocation();

    const returnFilterObjFromLine = (filterLine) => {
        let retObj = {};
        retObj[filterLine.field] = filterLine.value;
        return retObj;
    }


    const returnDateFilterObjFromLine = (filterLine) => {
        let retObj = {};
        retObj[filterLine.field] = {
            from: new Date(`01/01/${filterLine.value}`).toISOString(),
            to: new Date(`12/31/${filterLine.value}`).toISOString()
        };
        return retObj;
    }



    //call the engine with the filterItems applied
    const addFilters = (filterItems) => {
        //assumes a shape of filterItems = [{ field: 'field', value: 'value', type: 'text or date' }]
        //if necessary, in the future, we'll need to add support for ranges for dates, etc.
        let retFilters ={};

        //transform to the elastic search api filter
        
        if(filterItems.length > 1) {
            //if more than one filter line, treat as an AND
            retFilters = {
                all: []
            };

            filterItems.forEach( (item) => {
                let newFilterObj;
                if(item.type === 'date') {
                    newFilterObj = returnDateFilterObjFromLine(item);
                } else {
                    newFilterObj = returnFilterObjFromLine(item);
                }
                
                retFilters.all.push(newFilterObj);
            });

        } else if (filterItems.length === 1) {
            if(filterItems[0].type === 'date') {
                retFilters = returnDateFilterObjFromLine(filterItems[0]);
            } else {
                retFilters = returnFilterObjFromLine(filterItems[0]);
            }
        }

        return retFilters;
    };

    const getResults = async (pgNum) => {
        setLoading(true);
        let filters = addFilters(filterItems);
        //call the engine with the filters
        let results = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-elastic-content?engines=${engineToUse}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: '',
                filters: filters,
                page: {
                    size: paginationsize,
                    current: pgNum
                },
                sort: { date_published: 'desc' }
            })
        })

        let resJson = await results.json();

        //if there are errors, set the data to empty
        if(resJson.errors) {
            setCurData([]);
            setLoading(false);
            return;
        } 

        //set the data
        setCurData(resJson.results);

        //set the total pages
        setTotalResults(resJson.meta.page.total_results);
        setLoading(false);
    };

    const changedPage = ( pageNum ) => {
        //paginate handler passed into ListPagination
        setCurPage(pageNum);
        setPageChanged(true);
    }



    //initial load
    useEffect( () => {
        setCurPage(1);
        getResults(curPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterItems]);

    //when page changes
    useEffect( () => {
        if(pageChanged === true){
            getResults(curPage);
            setPageChanged(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageChanged]);

    //passed the list to the component that is passed in
    



    return (
        <div>
            {
                !loading ? 
                    <>
                        <FormattedArticleList
                            engineToUse={engineToUse}
                            list={formatForNewsList ? formatForNewsList(curData) : curData}
                            useStandardNewsLink={false}
                            showTags={false}
                            showInWorkspaceNews={true}
                            showBadges={true}
                            filterChanged={filterChanged}
                            filters={inputFilters} // for tags
                            filterOptions={filterOptions}
                            getNewsArticleTags={getNewsArticleTags}
                        />
                    {
                        paginationsize > 0 && totalResults > 0 ?
                            <ListPagination
                                articlesPerPage={paginationsize}
                                totalArticles={totalResults}
                                paginate={changedPage}
                                curPage={curPage}
                            />
                        : null
                    }
                    </>
                : <Spinner animation="border" role="status" /> 
            }
            
        </div>
    );
}

export default BasicElasticEngine;
