import CONSTANTS from '../constants';
import React from 'react';
import { Card, Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import utils from '../utils';
import NewsApp from './News/NewsApp';
import CompanyProfileApp from './CompanyProfiles/CompanyProfileApp';
import IntelligenceApp from './Intelligence/IntelligenceApp';
import AlchemyApp from './Alchemy/AlchemyApp';
import VaccineMarketsApp from './VaccineMarkets/VaccineMarketsApp';
import MarketActionsApp from './MarketActions/MarketActionsApp';

function TenantAppBox({ tenantApp, tenant, preview=false }) {

    const substringSubtitle = (subtitle) => {
        let subtitleInfo = subtitle.substring(0, 95) 
        if (subtitleInfo && subtitleInfo.length > 94) {
            subtitleInfo = subtitleInfo + '...'
        }
        return subtitleInfo;
    }

    return (
        <Col className="app-card-col" >
            <Card className={"app-card " + tenantApp.fields.apptype +' weird-corners text-center' + (preview ? ' preview ' : '')}>
                <div className="tenant-header">
                <Card.Title className="tenant-app-name align-left p-b-end-02 p-i-03">
                    { tenantApp.fields.appAlternateTitle ?  tenantApp.fields.appAlternateTitle : tenantApp.fields.apptype ? utils.getAppName(tenantApp.fields.apptype) : null } 
                </Card.Title>
                <Card.Subtitle className="text-muted align-left p-b-02 p-i-03">{ tenantApp.fields.description ? substringSubtitle(tenantApp.fields.description) : null }</Card.Subtitle>
                </div>
                <div className="inner-app-card">
                    {
                        tenantApp && tenantApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.NEWS ?
                        <NewsApp tenantSetting={ tenant } preview={ preview } />
                        :
                        tenantApp && tenantApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES ?
                        <CompanyProfileApp tenantSetting={ tenant } preview={ preview } />
                        :
                        tenantApp && tenantApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS ?
                        <VaccineMarketsApp tenantSetting={tenant} preview={preview} tenantApp={tenantApp} />
                        :
                        tenantApp && tenantApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.INTELLIGENCE ?
                        <IntelligenceApp tenantSetting={ tenant } preview={ preview } />
                        :
                        tenantApp && tenantApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.GLOBAL_HEALTH_ALCHEMY ?
                        <AlchemyApp preview={ preview }  tenantSetting={ tenant }/>
                        :
                        tenantApp && tenantApp.fields.apptype === CONSTANTS.APPS_BY_APPTYPE.MARKET_ACTIONS ?
                        <MarketActionsApp tenantSetting={ tenant } preview={ preview } tenantApp={tenantApp} />
                        :
                        preview ? 
                            tenantApp.fields.previewImage && tenantApp.fields.previewImage.url ? 
                                <div className='preview-img-div'>
                                    <Image className='preview-card-img' src={tenantApp.fields.previewImage.url}/> 
                                </div>
                                    : null
                            : null
                    }
                </div>
               <LinkContainer to={tenantApp.fields.customctalink ? tenantApp.fields.customctalink : `/${tenantApp.fields.apptype}`}>
                    <Card.Link className="secondary-link-plain preview-link">
                        { tenantApp.fields.ctatext ? tenantApp.fields.ctatext : 'Go' }
                    </Card.Link>
                </LinkContainer>
            </Card>
        </Col>
    )
}

export default TenantAppBox
