// useEffects are React18 ready
// TODO: (medium-high priority) enable alt_name editing. Roughly identical to the alt_names
// functionality in the organization editor

import React, { useState, useEffect } from 'react'; 

import _ from "lodash" // For making deep copies of objects
import { Form, Button, Row, Col, Modal, ButtonGroup, ButtonToolbar} from 'react-bootstrap'

import utils from "../../../utils"
import {useToast} from '../../ToastProvider'
import EnumSelector from '../EnumSelector';
import ObjectSelector from '../ObjectSelector';
import SimpleSelect from '../SimpleSelect';
import '../../../styles/layout/_layout.scss'

const YEAR_OPTIONS = Array.from({length: 41}, (v, k) => k + 2000)
const SHELL_MARKET = {market: null, confidentiality: null, category: null, capacity_types: [], subtypes: [], min_year: 2000, max_year: 2040, deleted: false}

function MarketEditor({apiKey, id, getFromLocalStore, closePopup, appClass, onSubmit, toastClick}) {
  
  /*--------------------------State variables---------------------------*/
  const [market, setMarket] = useState(null)
  const {addToast} = useToast();

  useEffect(()=>{
    if (getFromLocalStore === true) {
      fetchFromLocalStore();
    } else {
      fetchData();
    }
    return () => {};
    // eslint-disable-next-line
  },[apiKey, id, addToast])

  async function fetchData(){
    if (id){
      let res = await utils.getData('get_market', apiKey, "?market_id="+ id)
      if (typeof res === 'string') {
        addToast({
          title: 'Failed to get Market. Error code 4-a',
          body: res
        })
        closePopup();
      } else {
        if (res){
          setMarket(res);
        }
      }
    } else{
      setMarket(_.cloneDeep(SHELL_MARKET))
    }
  }

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();

    const result = await utils.upsert('upsert_market', apiKey, market)
    if (result !== null) {
      if (onSubmit){
        onSubmit(result)
      } 
      if (typeof result === 'string') {
        sendToLocalStorage();
        addToast({
          title: 'Failed to submit market. Error code 4-c',
          body: result,
          onClick: toastClick,
          buttonText: "Reopen market?"
        })
      }
      closePopup()
    }
  }

  function fetchFromLocalStore(){
    const obj = localStorage.getItem('errorMarket');
    const objJSON = JSON.parse(obj);
    setMarket(objJSON.market);
  }

  function sendToLocalStorage(){
    localStorage.setItem('errorMarket', JSON.stringify({market: market}));
  }



  return (
    <div>
      {
        market ?
          <Modal show={true} dialogClassName='modal-90w' className={appClass}>
            <Modal.Header>
              <Modal.Title>Market Editor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <div className='input-text'>Market *</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={market.market}
                    onChange={(e) => (e) = setMarket({ ...market, market: e.target.value })}
                  />
                </Col>
                <Col>
                  <div className='input-text'>Start Year *</div>
                  <SimpleSelect
                    className="select-max-100"
                    options={YEAR_OPTIONS}
                    value={market.min_year}
                    onChange={(e) => setMarket({ ...market, min_year: e })}
                  />
                </Col>
                <Col>
                  <div className='input-text'>End Year *</div>
                  <SimpleSelect
                    className="select-max-100"
                    options={YEAR_OPTIONS}
                    value={market.max_year}
                    onChange={(e) => setMarket({ ...market, max_year: e })}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="input-text">Confidentiality *</div>
                  <EnumSelector
                    className="select-max-100"
                    enum_type="confidentiality_type"
                    apiKey={apiKey}
                    disableCreate={true}
                    value={market.confidentiality}
                    onChange={(e) => setMarket({ ...market, confidentiality: e.label })}
                  />
                </Col>
                <Col>
                  <div className="input-text">Category *</div>
                  <EnumSelector
                    className="select-max-100"
                    enum_type="capacity_estimate_category"
                    apiKey={apiKey}
                    disableCreate={true}
                    value={market.category}
                    onChange={(e) => setMarket({ ...market, category: e.label })}
                  />
                </Col>
                <Col>
                  <div className="input-text">Deleted *</div>
                  <Form.Check
                    className="me-2"
                    checked={market.deleted}
                    onChange={() => setMarket({ ...market, deleted: !market.deleted })}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="input-text">Capacity Type *</div>
                  <EnumSelector
                    className="select-max-100"
                    enum_type="capacity_type"
                    apiKey={apiKey}
                    disableCreate={true}
                    value={market.capacity_types.map((item) => {return item.capacity_type})}
                    isMulti={true}
                    onChange={(e) => setMarket({ ...market, capacity_types: 
                      e.map((item) => {
                        return {capacity_type: item.label}
                      })
                    })}
                  />
                </Col>
                <Col>
                  <div className="input-text">Subtypes *</div>
                  <ObjectSelector
                      apiKey={apiKey}
                      obj={market.subtypes.map((item) => {return item.vaccine})}
                      isMulti={true}
                      isClearable={true}
                      type='vaccine'
                      onChange={(e) => setMarket({ ...market, subtypes: 
                        e.map((item) => {
                          return {vaccine: item, vaccine_id:item.vaccine_id}
                        })
                      })}
                  />
                </Col>
              </Row>


              <ButtonToolbar className='editor-btn'>
                <ButtonGroup>
                  <Button className='button-secondary m-i-end-02' onClick={closePopup} variant="secondary">Close</Button>{' '}
                </ButtonGroup>
                <ButtonGroup>
                  <Button className='btn-primary m-i-end-02' onClick={handleSubmit} variant="primary">Submit</Button>{' '}
                </ButtonGroup>
                <ButtonGroup>
                  <Button onClick={() => console.log(market)} variant="primary">Check Vaccine</Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Body>
          </Modal>
          : null
      }
    </div>
  )
}

export default MarketEditor;