import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CONSTANTS from '../../constants';

import PMTKanbanView from './Kanban/PMTKanbanView';
import PMTCreateEditItemPopup from './PMTCreateEditItemPopup';
import PMTNoItemInList from './PMTNoItemInList';
import PMTCONSTANTS from './pmtConstants';
import utils from '../../utils';
import ModalDynamic from '../Layout/ModalDynamic';
import pmtutils from './pmtutils';
import { useToast } from '../../components/ToastProvider';
import PMTMarketActionsToolbar from './PMTtoolbar/PMTMarketActionsToolbar';
import PMTTable from './Table/PMTTable';


function PMTDisplay({ tenant, selectedListItems, triggerLoading, listOptionHandler, selectionHandler, listOptions, memberList, suppliers, options, displayOptionHandler, apiKey, appName, handleReload, updateTopList, topList, handleListUpdate, fetchedEnums=null }) {

    
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    let [show, setShow] = useState(false);
    let [itemID, setItemID] = useState();
    let [selectedArchive, setSelectedArchive] = useState([]);
    let [listViewOption, setListViewOption] = useState('2'); // '2' is Kanban
    let [sepByField, setSepByField] = useState();
    let [ filteringForTable, setFilteringForTable ] = useState([]);
    const [marketOptions, setMarketOptions] = useState([]);
    const [uniqueObjectives, setUniqueObjectives] = useState([]);


    const { addToast } = useToast();
    let userInfo = utils.findUserInfo(tenant.ourUserID, memberList)

    let batchArchiveModal = {
        type: 'archive',
        title: utils.getTitle(`You’re about to archive ${selectedArchive.length} interventions`),
        body: `Archiving ${selectedArchive && selectedArchive.length > 1 ? `these ${selectedArchive.length} interventions` : 'this intervention'} 
        will remove ${selectedArchive && selectedArchive.length > 1 ? 'them' : 'it'} from all views. Are you sure you want to continue?`,
        app: 'market actions',
        subName: 'Archive',
    }

    const updateFiltering = (filterData) => {
        let filterCopy = [].concat(filteringForTable);
        //first, check if the filter is already in the list
        if(filterData && filterData.length > 0) {

            let idxOfFilter = filterCopy.findIndex( (item) => {
                return item.id === filterData.id;
            });

            if(idxOfFilter > -1) {
                filterCopy[idxOfFilter] = filterData;
            } else {
                filterCopy.push(filterData);
            }

            setFilteringForTable(filterCopy);
        } else {
            setFilteringForTable([]);
        }

       
    }


    //separates Kanban columns by fields
    const handleShowChange = (e) => {
        if (e && e.value) {
            setSepByField(e.value);
        }
        if (e && e.src === 'FILTERBY'  && listViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.TABLE) {
            updateFiltering(e.data)
        }
    }

    // handles view change
    const listViewHandle = (e) => {
        setListViewOption(e);
    }



    // Fetch the enum data for markets
    useEffect(() => {
        const fetchMarketEnums = async () => {
            try {
                const response = await utils.getData('get_enums', apiKey, '?enum_type=market_name_ma&include_deleted=false');
                const marketList = response.map(market => market.enum);

                if (tenant.tenant.fields.tenantslug === "LB-Level-Q") {
                    setMarketOptions(marketList);
                } else {
                    let filteredMkts = marketList.filter((market) => market !== 'Test Market')
                    setMarketOptions(filteredMkts)
                }
            } catch (error) {
                console.error('Error fetching market enums:', error);
            }
        };

        fetchMarketEnums();
    // eslint-disable-next-line
    }, [apiKey]);

    const filterListByPropNameAndComparatorAndValue = (pmtApp, list, propName, operator, value) => {

        const FILTER_TITLES_TO_KEYS =  {
            "intervention title": "item_name",
            "created": "created",
            "last updated": "last_updated",
            "status": "status",
            "hmf category": "hmf_category",
            "assigned to": "assigned_to"
        };
        
        if(propName) {

        
        let theRightProp = FILTER_TITLES_TO_KEYS[propName.toLowerCase()];
        
            switch(operator.toLowerCase()) {
                case 'does not equal':
                    return list.filter((item) => {
                        return item[theRightProp] !== value;
                    })
                case 'contains':
                    return list.filter((item) => {
                        return item[theRightProp].indexOf(value) > -1;
                    })
                case 'does not contain':
                    return list.filter((item) => {
                        return item[theRightProp].indexOf(value) < 0;
                    })
                case 'is greater than':
                    return list.filter((item) => {
                        return parseInt(item[theRightProp]) > parseInt(value);
                    })
                case 'is less than':
                    return list.filter((item) => {
                        return parseInt(item[theRightProp]) < parseInt(value);
                    })
                case 'is before':
                    return list.filter((item) => {
                        let theDate = new Date(item[theRightProp]);
                        let soughtDate = new Date(value);
                        if(!isNaN(theDate) && !isNaN(soughtDate)) {
                            return theDate.getTime() < soughtDate.getTime();
                        } else {
                            return false;
                        }})
                case 'is after':
                    return list.filter((item) => {
                        let theDate = new Date(item[theRightProp]);
                        let soughtDate = new Date(value);
                        if(!isNaN(theDate) && !isNaN(soughtDate)) {
                            return theDate.getTime() > soughtDate.getTime();
                        } else {
                            return false;
                        }})
                default:
                    //equals
                    return list.filter((item) => {
                        return item[theRightProp] === value;
                    })
            }
        }

    }

    //Filters out only the first list item on load or the listNames that are selected.
    const filterToListsSelected = (list, selected, sepField) => {
        let retArr;
        
        if (selected && selected.length > 0) {
            //filters to show testmarket only leave for trouble shooting
            let filteredArray = list.filter((listObj) => selected.includes((listObj.list_name)));
    
            //check if filters are applied if so display filtered data
            let listWithFilters = checkForFilters(filteredArray, options)
    
            if (listWithFilters && listWithFilters.length > 0) {
                retArr = listWithFilters;
            } else {
                retArr = filteredArray;
            }

        } else {
            retArr = [];
        }

        if (sepField !== undefined && sepField !== null) {
            if(sepField.length > 0) {
                //go through each filter and apply in order
                sepField.forEach((sepField, idx) => {
                    retArr = filterListByPropNameAndComparatorAndValue("pmt_ma", retArr, sepField[idx].fieldForFilter, sepField[idx].operator, sepField[idx].comparator)
                })
            }
            
        }

        return retArr;
    }
    // Shows the modal for single item mode
    const handleShowListItemModal = (id = null) => {
        if (id) {
            setItemID(id)
        }
        if (show === true) {
            setItemID(null);
        }
        setShow(!show);
    }

    // filters for date range
    const filterInDateRange = (months, allListItems) => {
        let temp = allListItems
        let startDate = new Date()
        let startDateWithTimeStamp = startDate.getTime()
        let endDate = new Date().setMonth(startDate.getMonth() + months)

        let filteredList = temp.filter((listItem) => {
            let dueDate = new Date(listItem.due_date).getTime();
            return dueDate && dueDate >= startDateWithTimeStamp && dueDate <= endDate
        })
        return filteredList;
    }

    //Check if filters exist if so filter to selected items
    const checkForFilters = (tempArr, opts) => {
        let filteredList = [];

        if (opts['Show Archived'] === true) {
            filteredList = tempArr;
        }
        if (opts['Due in the next 6 months'] === true) {
            filteredList = filterInDateRange(6, tempArr)
        }
        if (opts['Due in the next year'] === true) {
            filteredList = filterInDateRange(12, tempArr)
        }
        return filteredList;
    }
    

    //sets the selected items to archive in state
    const handleArchiveSelection = (e) => {
        let id = e.target.name;
        let copyList = [...topList]// all interventions in all markets
    
        let listItem = copyList.filter((item) => item.item_id === id)
    
        if(selectedArchive && selectedArchive.length > 0 && (selectedArchive.findIndex(item => item.item_id === listItem[0].item_id) > -1)) {
            let filteredList = selectedArchive.filter((item) => item.item_id !== listItem[0].item_id)
            setSelectedArchive(filteredList);
        } else {
            setSelectedArchive([...selectedArchive, listItem[0]])
        }
    }

    // toggles archive, updates the list, resets selected state
    const toggleArchived = () => {
        let copyList = [...selectedArchive];
        let updatedList = copyList.map((item) => {
            let val = item.deleted === true ? false : true;
            item.deleted = val;
            return item;
        })

        handleBatchArchive(updatedList)
        setShowArchiveModal(false)
        setSelectedArchive([]);
    }

    //sends items to API to update
    const archiveData = async(item, app) => {
        await pmtutils.updateListItem(item, app, apiKey, addToast)
    }

    //formats data to what backend expects and loops through if multiple to send to backend
    const handleBatchArchive = (updatedList) => {
        let app = appName.fields.apptype ? appName.fields.apptype : appName.fields.apptype;
    
        updatedList.forEach((item) => {
            let listItem = pmtutils.sendToFormatter(appName, item, userInfo)
            let itemToSend = listItem && listItem[0] ? listItem[0] : listItem;
            archiveData(itemToSend, app)
        });
        // retriggers load
        triggerLoading();
      }
  
    const updatedData = (id, fieldName, value) => {
        //updates for enumselectors and fields like hmf_category
        if (value && Array.isArray(value) && value.length > 0) {
            let valsToSend = value.map((val) => { return val.label })
            handleListUpdate({ id: id, field: fieldName, value: valsToSend });
        } else {
            handleListUpdate({ id: id, field: fieldName, value: value });
        }
    }
    
    const getAssigneesList = (assignees) => {
        return assignees.map((assignee) => {
            return { label: assignee.mail, value: assignee.mail };
        })
    }

    const getSupplierList = (splrs) => {

        return splrs.map((item) => {
            return { label: item, value: item};
        })
    }

    // Get unique values for autocomplete 
    function getUniqueValues(data, columnName) {
        const uniqueValues = new Set();
        
        data.forEach(row => {
          if (row[columnName]) {
            uniqueValues.add(row[columnName].trim());
          }
        });
        
        return Array.from(uniqueValues);
      }
      

      useEffect(() => {
        const uniqueValues = getUniqueValues(topList, 'customField_1');
        setUniqueObjectives(uniqueValues);
      }, [topList]);
      

    return (
            <div>
                {
                    appName && appName.fields.apptype === "pmt_ma" ?
                        <PMTMarketActionsToolbar
                            toggleArchive={() => selectedArchive && selectedArchive.length > 0 ? setShowArchiveModal(!showArchiveModal) : setShowArchiveModal(showArchiveModal)}
                            topList={topList}
                            listViewOption={listViewOption}
                            selectionHandler={selectionHandler}
                            handleShowChange={handleShowChange}
                            listViewHandle={listViewHandle}
                            listOptions={listOptions}
                            selectedListItems={selectedListItems}
                            appName={appName}
                            options={options}
                            filterToListsSelected={filterToListsSelected}
                            handleShowListItemModal={handleShowListItemModal}
                            displayOptionHandler={displayOptionHandler}
                            listOptionHandler={listOptionHandler}
                            memberList={memberList}
                            apiKey={apiKey}
                            marketOptions={marketOptions}
                        />
                    : ''
                }
                {
                        <Row>
                            <Col className={`view-display ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[listViewOption]}`}>
                                {
                                    topList && topList.length > 0 ?
                                        listViewOption && listViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.KANBAN ?
                                            <PMTKanbanView
                                                listNames={selectedListItems}
                                                topList={filterToListsSelected(topList, selectedListItems)}
                                                onUpdate={handleListUpdate}
                                                separateByField={sepByField}
                                                tenant={tenant}
                                                appName={appName}
                                                assigneesToPass={memberList}
                                                handleShowListItemModal={handleShowListItemModal}
                                                handleArchiveSelection={handleArchiveSelection}
                                                selectedArchive={selectedArchive}
                                            />
                                        :
                                            <PMTTable 
                                                tableHeaderConfig={pmtutils.getTableconfig('MARKET_ACTIONS', appName, topList, updatedData, apiKey)}
                                                formattedList={filterToListsSelected(topList, selectedListItems, filteringForTable)}
                                                appName={appName.fields.apptype}
                                                handleListUpdate={updatedData}
                                                apiKey={apiKey}
                                                configType={'MARKET_ACTIONS'}
                                                organization={getSupplierList(suppliers)}
                                                memberList={memberList}
                                                priorities={PMTCONSTANTS.PRIORITY_SELECT}
                                                assigneesList={getAssigneesList(memberList)}
                                                fetchedEnums={fetchedEnums}
                                                handleShowModal={handleShowListItemModal} 
                                                uniqueObjectives={uniqueObjectives}

                                            />

                                        :
                                        <PMTNoItemInList
                                            header={`There are no ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.apptype].toLowerCase()}s in this ${PMTCONSTANTS.LIST_NAME[appName.fields.apptype].toLowerCase()}`}
                                            tenant={tenant}
                                            handleShowAddItemModal={handleShowListItemModal}
                                            body={`Add ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.apptype].toLowerCase()}s below, or go back to ${PMTCONSTANTS.APP_NAMES[appName.fields.apptype]} home page`}
                                            dynamicItemName={'interventions'}
                                            dynamicAppName={tenant.tenant.tenantslug === 'Gavi MST' ? 'Roadmap Actions' : 'Market Actions'}
                                            appUrl='market_actions'
                                        />
                                }
                            </Col>
                        </Row>
                }
                {
                    show && show === true ?
                        <PMTCreateEditItemPopup
                            updateTopList={updateTopList}
                            tenant={tenant}
                            groupMembers={memberList}
                            show={show}
                            handleHideModal={() => setShow(false)}
                            listName={selectedListItems && selectedListItems.length > 0 ? (filterToListsSelected(topList, selectedListItems)[0] && filterToListsSelected(topList, selectedListItems)[0].list_name ? filterToListsSelected(topList, selectedListItems)[0].list_name : null): null} 
                            itemID={itemID ? itemID : null}
                            mode={itemID && itemID !== null ? 'edit' : 'create'}
                            topList={topList}
                            appName={appName}
                            apiKey={apiKey}
                            clearItemID={() => setItemID()}
                            handleReload={handleReload}
                            suppliers={suppliers}
                            currentView={listViewOption}
                            listNames={listOptions} 
                            marketOptions={marketOptions}
                            uniqueObjectives={uniqueObjectives}

                        />
                        : null
                }
                {
                    selectedArchive && selectedArchive.length > 0 && showArchiveModal && showArchiveModal === true ?
                        <ModalDynamic 
                            classes={'archive-confirm-modal'}
                            id='archive-confirm-modal'
                            show={showArchiveModal}
                            content={batchArchiveModal}
                            tenant={tenant.tenant}
                            handleClose={() => setShowArchiveModal(false)}
                            animation={false}
                            submitRequest={toggleArchived}
                        />
                    : null
                }
            </div>
    )
}

export default PMTDisplay;