import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import SubNavPopover from './SubNavPopover';

function UrlDrivenTopNav({ 
            apppath, 
            navs, 
            fields=null, 
            sendNavItemToShow, 
            item=null,
            activeTab=null,
            subUrlVal=null,
            subItems=null 
        }) {
    const [activePopover, setActivePopover] = useState(null)

    const togglePopover = (nav, popoverId) => {
        setActivePopover((prev) => (prev === popoverId ? null : popoverId));
    };


    const closePopover = () => {
        setActivePopover(null)
    }

    if(item && subItems) {
        let theSubURLVal
        if(subUrlVal) {
            theSubURLVal = subUrlVal;
        } else {
            theSubURLVal = item.fields.slug;
        }
        apppath = apppath + `/${theSubURLVal}`;
    }


    return (
        <div className='url-driven-topnav border-bottom-light m-b-end-08' >
            {

                navs && navs.length > 0 ? (
                    navs.length === 1 ? (
                        // Handle single navigation item - top level only
                        navs[0].subNav.map((navItem, index) => (
                            <LinkContainer to={`/${apppath}/${navItem.urlForField}`} key={index}>
                                <Button className={activeTab && activeTab.url === navItem.urlForField ? 'tab-nav-btn active-tab' : 'tab-nav-btn'} onClick={() => sendNavItemToShow(null)}>{navItem.linkLabel}</Button>
                            </LinkContainer>
                        ))
                    ) : (
                        // Handle multiple navigation items - sublevels
                        navs.map((navItem, index) => (
                            <SubNavPopover
                                key={index}
                                isActive={activePopover === index}
                                togglePopover={(nav) => togglePopover(nav, index)}
                                closePopover={closePopover}
                                sendNavItemToShow={sendNavItemToShow}
                                navList={navItem}
                                apppath={apppath}
                                activeTab={activeTab}
                                navItem={navItem.groupingTitle}
                                navLevelThree={
                                    fields && fields.fields && fields.fields.capabilityAnalysesbyCompany
                                        ? fields.fields.capabilityAnalysesbyCompany
                                        : null
                                }
                            />
                        ))
                    )
                ) : null
            }
        </div>
    )
}

export default UrlDrivenTopNav;