import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CONSTANTS from '../../constants';

import CompanyAnalysisFieldDisplay from './CompanyAnalysisFieldDisplay';

function CompareCompTabs({ analysisRec }) {

    const [selectedTab, setSelectedTab] = useState(CONSTANTS.COMPANY_CAPABILITY_ANALYSES_TABS[0].title);

    const tabSelected = (key) => {
        setSelectedTab(key)
    }
    
    return (
        <Tabs
            id={analysisRec.fields.companyName.replace(' ', '_')+'_tab'}
            className="mb-3" 
            onSelect={tabSelected}
            fill >
            {
                CONSTANTS.COMPANY_CAPABILITY_ANALYSES_TABS.map((thisTab) => {
                    return (
                        <Tab eventKey={thisTab.title} title={thisTab.title}>
                            <CompanyAnalysisFieldDisplay
                                currentlyShown={selectedTab === thisTab.title}
                                fieldsToDisplay={analysisRec.fields}
                                tabInfo={thisTab} />
                        </Tab>
                    )
                })
            }
        </Tabs>
    )
}

export default CompareCompTabs