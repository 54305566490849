import React from "react";
import { useHistory } from "react-router-dom";

function CountryNewsWrapper({ cntryName, children }) {
    const history = useHistory();

    const handleBadgeClick = (tag) => {
        const params = new URLSearchParams();
        if (cntryName) params.set("country", cntryName); // Include the current country
        if (tag.field === "country") params.set("country", tag.value);
        if (tag.field === "disease") params.set("disease", tag.value);
        history.push(`/ce_news?${params.toString()}`);
    };

    return (
        <div className="company-field-display">
            <h4>News</h4>
            <p>
                Campaign news stories from {cntryName}. Published monthly by Linksbridge. Subscribe to the Health Campaigns News Roundup{" "}
                <a
                    href="https://share.hsforms.com/1PL1zDURDTViPLlDIcqvz3w54nwk?utm_medium=email&amp;_hsenc=p2ANqtz-8MuGrt--f-vV-udpmPEhULXxSL9AC0_0d8EX5pMKCjbpdShW0if13inYTuzdS4PkMo7HRnCgfRkl9LueIwMLIfb6R0ag0K4FdMR9k0pFmSDwe9PBc&amp;_hsmi=319475472&amp;utm_content=319475472&amp;utm_source=hs_email"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>
                .
            </p>
            {React.cloneElement(children, { filterChanged: handleBadgeClick })}
        </div>
    );
}



export default CountryNewsWrapper;
