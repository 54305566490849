import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';

function CountryMap( { country } ) {
    const [coords, setCoords] = useState();
    const [loading, setLoading] = useState(true);
    const acc_tkn = process.env.REACT_APP_MAPBOX_TOKEN;

    const getCoords = async (countryName) => {
        if(countryName === 'CAR'){
            countryName = 'Central African Republic'
        }
        
        setLoading(true);
        //make a call to the places api to get coords for country map
        try {
            const resp = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${countryName}&access_token=${acc_tkn}&types=country&format=v5`);
            const res = await resp.json();

            //make sure there is expected shape of response
            if (res.hasOwnProperty('features') && res.features.length > 0){
                setCoords(
                    res.features[0].bbox
                );
            }
            
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        
    };

    useEffect( () => {
        getCoords(country.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[country])
    
    return (
        <div className="mapbox-map-container">
            { 
                !loading ?
                    <img 
                        src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/[${coords}]/600x600?access_token=${acc_tkn}&add_layer=admin-1-boundary-bg`} 
                        alt="political map of country" />
                
                : <Spinner animation="border" size="lg" />
            }
        </div>
    )
}

export default CountryMap
