const filterUtils = {
    findFilterTitle: (defaultLabelName, filters, listToCheck, showDefaultLabelAlt=null) => {
        let labelName = showDefaultLabelAlt ? showDefaultLabelAlt : defaultLabelName;

        if (filters) {
            // Find the filter matching the field
            let fndIndex = filters.findIndex((item) => {
                return item.field.toLowerCase() === defaultLabelName.toLowerCase();
            });

            if (fndIndex > -1) {
                const filterValue = filters[fndIndex].value;

                // Normalize the filter value (handle hierarchical values such as in some CE News articles)
                const normalizedFilterValue = filterValue.includes(">") 
                    ? filterValue.split(">").pop() // Extract "Nigeria" from "Africa>Nigeria"
                    : filterValue;

                // Find the corresponding entry in the list
                let foundIndex = listToCheck.findIndex((item) => {
                    return item.value.toLowerCase() === filterValue.toLowerCase()
                        || item.label.toLowerCase() === normalizedFilterValue.toLowerCase(); // Match on label or value
                });

                if (foundIndex > -1) {
                    return listToCheck[foundIndex].label; // Return the matched label
                }

                return normalizedFilterValue; // Fallback to normalized value
            }
        }

        return labelName; // Default fallback
    },
};

export default filterUtils;
