// useEffects are React18 ready
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'

import StatefulSearchTable from '../StatefulSearchTable';
import MarketEditor from './MarketEditor'
import {useToast} from '../../ToastProvider'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData=[
  {title:'Market', dispField:'market', filterField:'market'},
  {title:'Confidentiality', dispField:'confidentiality', filterField:'confidentiality'},
  {title:'Category', dispField:'category', filterField:'category'},
  {title:'Capacity Types', dispField:'capacity_types', filterField:'capacity_types'},
  {title:'Subtypes', dispField:'subtypes', filterField:'subtypes'}
]

const sortData=[
  {title:"Market", field:'market'}
]

async function fetchData(setMarkets, apiKey, addToast) {
  setMarkets([])
  let result = await utils.getData("get_markets", apiKey)
  if (typeof result === 'string'){
    addToast({
      title: 'Failed to load data. Error code 4-d',
      body: result
    })
  } else {
    if (result !== null) {
      setMarkets(result);
    }
  } 
}

function MarketSearch(props) {
  // Local representation with data augmentations
  const [markets, setMarkets] = useState([])
  // Product that gets edited when double clicked
  const [marketId, setMarketId] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditMarket, setShowEditMarket] = useState(false)
  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  
  const {addToast} = useToast();
  const [getFromLocalStore, setGetFromLocalStore] = useState(false);

  
  
  useEffect(() => {
    if (showEditMarket === false) {
      fetchData(setMarkets, props.apiKey, addToast)
    }
    return () => {};
  }, [showEditMarket, props.apiKey, addToast]
  )


  function toggleShowEditMarket() {
    setShowEditMarket(!showEditMarket)
  };

  function createNew() {
    toggleShowEditMarket()
    setMarketId(null)
    setGetFromLocalStore(false);
  }

  function update(market) {
    toggleShowEditMarket()
    setMarketId(market.market_id)
    setGetFromLocalStore(false);
  }

  function openLocalStoreMarket(){
    setShowEditMarket(true);
    setMarketId(null);
    setGetFromLocalStore(true);
  }

  // Passed into search table to add buttons to the left of the required buttons
  const toolbarButtons = () => {
    return (
      <>
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  return (
    <div>
      {showEditMarket ?
        <MarketEditor 
          id={marketId}
          handleSubmit={toggleShowEditMarket}
          closePopup={toggleShowEditMarket}
          apiKey={props.apiKey}
          appClass={props.appClass}
          getFromLocalStore={getFromLocalStore}
          toastClick={openLocalStoreMarket}
        />
        : null
      }
      <StatefulSearchTable
        data={markets}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        apiKey={props.apiKey}
        editor={props.editor}
        module='wap'
        rowsPerPage={20}
        toolbarButtons={toolbarButtons}
        appClass={props.appClass}
      />
    </div>
  )
}

export default MarketSearch;