import PMTformatUtils from "../../PMTformatUtils";
import PMTCONSTANTS from "./pmtConstants";
import PMTtableConfig from "./Table/PMTtableConfig";

const pmtutils = {

    filterToSearchTerm: (list, term) => {
        if (term && term !== '') {
          let retList = list.filter((item) => {
            return item.list_name.toLowerCase().includes(term.toLowerCase())
          })
          return retList;
        } else {
          return list;
        }
      },

    getMultiArrayObj: (field, val, id) => {
        switch(field) {
            case 'customField_3':
                let retArr = val.map((item) => {
                    let obj = {}
                    let val;
                    if (item && item.label){
                        val = item.label
                    }
                    else if(item && item[0] && item[0].label) {
                        val = item[0].label;
                    }
                    else if(item && item.hmf_category) {
                        val = item.hmf_category;
                    } 
                    else {
                        val = item;
                    }

                    obj.hmf_category = val;
                    obj.intervention_id=id;
                    return obj;
                });
                return retArr;
            default:
            break;
        }
    },
    //Will add more cases as add apps
    //finds the correct formater to send the form data to to reformat to what is expected in the backend
    sendToFormatter: (app, formItem, user) => {
        let infoToSend;
        switch (app.fields.apptype) {
            case "pmt_ma":
                infoToSend = PMTformatUtils.deformatMarketActions(formItem, user);
                return infoToSend;
            case "pmt_demand":
                infoToSend = PMTformatUtils.deformatDemandHealth(formItem, user);
                return infoToSend;
            default:
             break;
        }
    },

    //Alphabetizes toplist
    alphabetizedList: (list) => {
        let alphaList = list.sort((a, b) => a.list_name.localeCompare(b.list_name));
        return alphaList;
    },

    //Formats the data to custom_fields based on app
    formatDataBasedOnApp: (list, dataType) => {
        if( list && list.length > 0 ) {
            if (dataType === 'OBJECTIVES') {
                let retArr = PMTformatUtils.formatDemandHealth(list)
                    pmtutils.alphabetizedList(retArr)
                return retArr;
            }
        } else {
            pmtutils.alphabetizedList(list)
        }
    },
        
    getInitialLabelVals: (fieldArr) => {
        let retArr = [];
        if (fieldArr && fieldArr.length > 0) {
            fieldArr.forEach((item) => { 
                if(item && item.hmf_category) {
                    retArr.push(item.hmf_category)
                }
                if(item && item.label) {
                    retArr.push(item.label)
                }
            })
        }
        return retArr;
    }, 

    updateListItem: async (item, app, apiKey, addToast) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${PMTCONSTANTS[app].UPSERT_ITEM}?${PMTCONSTANTS[app].ID_PARAM}=${item[PMTCONSTANTS[app].ID]}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'access_token': apiKey
                //need to add other access token for workspace access here
              },
              body: JSON.stringify(item)
            })
            if (result['status'] === 403) {
              addToast({
                title: 'isValid Key',
                body: "Invalid API key, access denied"})
            }
          } catch (error) {
            addToast({
              title: 'Update Error',
              body: 'Whoops!  something happened on this submit.  Please try again.'
            })
         }
    },
    getTableconfig: (tableType, app, list, updateListItemForItemChange, key) => {
        return PMTtableConfig[tableType](app, list[0], updateListItemForItemChange, key)
    },
    filterListByPropNameAndComparatorAndValue: (pmtApp, list, propName, operator, value) => {

        const FILTER_TITLES_TO_KEYS =  {
            "intervention title": "item_name",
            "created": "created",
            "last updated": "last_updated",
            "status": "status",
            "hmf category": "hmf_category",
            "assigned to": "assigned_to"
        };
        
        switch(operator.toLowerCase()) {
            case 'does not equal':
                return list.filter((item) => {
                    let theProp = FILTER_TITLES_TO_KEYS[propName];
                    return item[theProp] !== value;
                })
            case 'contains':
                return list.filter((item) => {
                    let theProp = FILTER_TITLES_TO_KEYS[propName];
                    return item[theProp].indexOf(value) > -1;
                })
            case 'does not contain':
                return list.filter((item) => {
                    let theProp = FILTER_TITLES_TO_KEYS[propName];
                    return item[theProp].indexOf(value) < 0;
                })
            case 'is greater than':
                return list.filter((item) => {
                    let theProp = FILTER_TITLES_TO_KEYS[propName];
                    return parseInt(item[theProp]) > parseInt(value);
                })
            case 'is less than':
                return list.filter((item) => {
                    let theProp = FILTER_TITLES_TO_KEYS[propName];
                    return parseInt(item[theProp]) < parseInt(value);
                })
            default:
                //equals
                return list.filter((item) => {
                    let theProp = FILTER_TITLES_TO_KEYS[propName];
                    return item[theProp] === value;
                })
        }

    },

    stripOutEnum: (list) => {
        if (!Array.isArray(list)) {
            console.error('Invalid data passed to stripOutEnum:', list);
            return [];
        }
        return list.map((item) => ({
            label: item.enum,
            value: item.enum,
        }));
    },
}

export default pmtutils;