import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import CountryFilter from './CountryFilter';
import DiseaseFilter from './DiseaseFilter';
import YearFilter from './YearFilter';

function CEFilterBar({ onFilterChanged, countryList, diseaseList, yearList, curFilters }) {
  // curFilters example = [{field: 'country', value: 'USA'}, {field: 'disease', value: 'Malaria'}, {field: 'integrated', value: true}, {field: 'non-integrated', value: false}]
  //three possible states for integration: ALL (Both Checks False), JUST INTEGRATED, JUST NON-INTEGRATED 

  //the role of this component is to adapt general filter to the CE Domain
  const myFilterListChanged = (e) => {
    let fieldName, fieldValue;

    //if the e has a target - then it is the checkboxes, not the complex filters
    if(e.target) {
      fieldName = e.target.name;
      fieldValue = e.target.type === 'checkbox' ? e.target.checked: e.target.value;
    } else {
      fieldName = e.field;
      fieldValue = e.value;
    }

    onFilterChanged({
      field: fieldName,
      value: fieldValue
    });
  }

  const getIntegrationFilterAndValue = (filterItem) => {
      if(filterItem.value === false) {
        return {field: 'non-integrated', value: true};
      } else if(filterItem.value === true) {
        return {field: 'integrated', value: true};
      } else {
        return null;
      }
  }

  

  const filtersHasPick = (whichFilter, filterArray) => {
    //check if the filters have an item and the value if so
    
    //adapt from elastic to CE

    if(whichFilter && whichFilter !== undefined){

      if(whichFilter) { 
        let found = filterArray.find((item) => {
          return item.field.toLowerCase() === whichFilter.toLowerCase();
        });
      
        if(found) {
          //integration is special - if non-integrated is checked, then integrated is false
          //if integrated is checked, then integrated is true
          //if both are unchecked, then integration is not in the filter
          return getIntegrationFilterAndValue(found);
        }
      }
    }

    return null;
  }
  
  return (
    countryList && diseaseList ?
      <>
      <Row className='extra-spacer'>
        <Col>
          <CountryFilter
            countryList={countryList}
            filterChangeHandler={myFilterListChanged}
            curFilters={curFilters} />
        </Col>
      </Row>
      <Row className='extra-spacer'>
        <Col>
          <DiseaseFilter
            diseaseList={diseaseList}
            filterChangeHandler={myFilterListChanged}
            curFilters={curFilters} />
        </Col>
      </Row>
      <Row className='extra-spacer'>
        <Col>
          <YearFilter
            yearList={yearList}
            filterChangeHandler={myFilterListChanged}
            curFilters={curFilters} />
        </Col>
      </Row>  
      <Row>
        <Col>
          <h5 className='int-h5'>Integration</h5>
        </Col>
      </Row>  
      <Row>
        <Col>
          <div className='checkbox'>
            <input type='checkbox'
              name="non-integrated"
              checked={filtersHasPick('non-integrated', curFilters)}
              onChange={myFilterListChanged} />
             <label style={{marginInlineStart: '6px'}} for="integration-none"> Non-integrated Campaigns</label>
          </div>
        </Col>
      </Row>  
      <Row>
        <Col>
          <div className='checkbox'>
            <input type='checkbox'
              name="integrated"
              checked={filtersHasPick('integrated', curFilters)}
              onChange={(e) => myFilterListChanged(e)}/>
             <label style={{marginInlineStart: '6px'}} for="integration-campaigns"> Integrated Campaigns</label>
          </div>
        </Col>
      </Row>  
      </>
      :
      <Spinner animation='border' />
  )
}

export default CEFilterBar;