import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { getName } from 'country-list';
import { useToast } from '../../components/ToastProvider';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import ircUtils from '../../components/IRC/ircUtils';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import CountryHarness from '../../components/IRC/CountryHarness';
import { useParams, useHistory } from 'react-router-dom';
import BreadCrumbBar from '../../components/Layout/Nav/BreadCrumbBar';
import CountryPicker from '../../components/IRC/CountryPicker';
import WorldVectorMap from '../../components/IRC/WorldVectorMap';


function IRCAppPage({ tenant }) {

  const { cntryISO } = useParams();
  const { addToast } = useToast();

  const history = useHistory();

  let [contentModel, setContentModel] = useState();
  let [loading, setLoading] = useState(true);
  let [ navDescr, setNavDescr ] = useState({});
  let [ countriesList, setCountriesList ] = useState([]);
  
  const { instance, accounts } = useMsal();

  let tenantSlug = tenant.tenant.fields.tenantslug;

  let tenID = utils.getForceTenantValue();

  if (!tenID) {
    //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
    tenID = tenant.tenant.fields.aDGroupID || null;
    utils.setForceTenantValue(tenID);
  }

  const request = {
    scopes: ["User.Read"]
  };

  const trackCall = async (accToken, appType) => {
    //no await on purpose, we don't need to ensure that it returns - just issue it
    utils.trackAppView(appType, tenID, accToken);
  }

  const getAppTitle = (tnt, appType) => {
    let appInQuestion = tnt.tenant.fields.apps.find( (thisApp) => {
      return thisApp.fields.apptype.toLowerCase() === appType.toLowerCase();
    });

    return appInQuestion && appInQuestion.fields && appInQuestion.fields.appAlternateTitle ? appInQuestion.fields.appAlternateTitle : null;
  };

  const getContentModel = async (token) => {
    let extraParams = 'force_id=' + tenant.tenant.fields.aDGroupID;

    let addedParams = '';

    if(extraParams && extraParams.length > 0) {
        addedParams = `&${extraParams}`;
    }
    
    //use the app fields test list
    if(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase() === 'dev') {
        addedParams = addedParams + '&use_test_list=true';
    }

    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-tenant-content?app=${CONSTANTS.APPS_BY_APPTYPE.IRC}&userCGs=${tenantSlug}&uid=${tenant.ourUserID}${addedParams}`, {
        headers: {
          "Authorization": "Bearer " + token.accessToken,
          "Content-Type": "application/json"
        }
    });

    let jsonResp
    try{
      jsonResp = await response.json();
    } catch (error) {
      addToast({
        title: 'An error occurred',
        body: 'Cannot load needed Data - please refresh browser.',
      });
    }

    if (jsonResp.appFields) {
      setNavDescr(jsonResp.appFields);
    }

    setContentModel(jsonResp.content[0]);
  }

  const getCountriesList = async (userType='GAVI') => {

    try {
      const res = await fetch(`${process.env.REACT_APP_DB_API_URL}get_gavi_countries`, {
          headers: {
            'Content-Type': 'application/json',
            "access_token": process.env.REACT_APP_DB_PUBLIC_KEY
          }
        });
      const data = await res.json();
      setCountriesList(data);
    } catch( err ) {
        addToast('Cannot load countries list - please refresh browser.', 'An error occurred', 'danger');
    }
  }

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        let accToken = await utils.getAccessToken(instance, accounts[0], request);
        // only track if on prod
        if(process.env.REACT_APP_ENV === 'PROD' ) {
          trackCall(accToken, 'irc');
        }
        getContentModel(accToken);
        getCountriesList();
      })()
    } catch (error) {
      addToast('Cannot load needed Data - please refresh browser.', 'An error occurred', 'danger');;
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);



  const mapCountryPickHandler = (pickedCntryCode) => {
    //we have to convert jvector country code to ISO
    //match the clicked code back to our 3 letter format
    let threeLetterCoded = getName(pickedCntryCode);

    let ogObj = ircUtils.getThreeLetter(threeLetterCoded, countriesList);


    pickCountryHandler(ogObj);
  }

  const pickCountryHandler = (picked) => {
    history.push('/irc_app/' + picked.ISO)
  }

  const findCountryNameFromISO = (isoCode, cntrList) => {
    let isoMatch = isoCode ? cntrList.filter((country) => country.ISO.toUpperCase() === isoCode.toUpperCase()) : null

    if (isoMatch && isoMatch.length > 0) {
      return isoMatch[0].country;
    } else {
      return '';
    }
  }

  return (
    !loading && contentModel && countriesList?
      <div className='irc-app'>
        <BreadCrumbBar 
          appName={'IRC'} 
          appAltTitle={getAppTitle(tenant, CONSTANTS.APPS_BY_APPTYPE.IRC)}
          displayFieldName={'iso'} 
          item={cntryISO ? { iso: findCountryNameFromISO(cntryISO, countriesList) }: null} />

        <div className={`app-page-display ${CONSTANTS.APPS_BY_APPTYPE.IRC}`}>
          <h1 className='h1 m-b-start-08 m-b-end-08'>{findCountryNameFromISO(cntryISO, countriesList) ? `${findCountryNameFromISO(cntryISO, countriesList)} Country Profile` : CONSTANTS.APPS_BY_NAME.COUNTRY_PROFILES}</h1>
          
              {
                cntryISO ?
                  <CountryHarness 
                    navDescr={navDescr}
                    tenant={tenant} 
                    selCountry={cntryISO} 
                    fullCountryList={countriesList}
                    contentFromRec={contentModel} />
                :
                  <>
                  <Row>
                    <Col >
                      <CountryPicker 
                        countries={countriesList} 
                        onPick={pickCountryHandler} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <WorldVectorMap 
                        regionClickHandler={mapCountryPickHandler} 
                        highlightedCountries={ircUtils.createJVector(countriesList)} /> 
                    </Col>
                  </Row>
                    
                  </>
              }
            
        </div>
      </div>
    : <ListSkeletonLoader preview={false} />

  )
}

export default IRCAppPage;
